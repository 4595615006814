<template>
  <div class="box">
    <span class="headline cb-headlinenormalxl g-3"  v-if="!AgendaEntry">Not found</span>
    <div class="row g-3"  v-if="AgendaEntry">

      <div class="col">
        <h4><i class="fal fa-calendar-alt"></i> {{formatedDate(AgendaEntry.startdate,AgendaEntry.enddate)}} CEST</h4>
        <h4 class="mb-3 mt-5 bold"><span v-html="title(AgendaEntry.title)"></span> <span v-if="AgendaEntry.subtitle">- <span v-html="AgendaEntry.subtitle"></span></span></h4>
        <p v-if="AgendaEntry.hosted_by" class="bold">Hosted by: <div v-html="AgendaEntry.hosted_by"></div></p>
        <div class="row agenda_item_full">
          <div class="col-12">
            <span class="Sendung_Beschreibung_full"  v-html="AgendaEntry.long_summary">
            </span>
          </div>
        </div> 
        <div class="row mb-5 mt-5">
          <div class="col-lg-3 col-6" v-if="AgendaEntry.room.length > 0 || AgendaEntry.stream.length > 0">
            <span class="headline_light text-center">Takes place at</span><br>
            <span v-if="AgendaEntry.room"><i class="fal fa-2x fa-map-marker-alt"></i> <span v-html="AgendaEntry.room"></span><br></span>
            <span v-if="AgendaEntry.stream"><i class="fal fa-2x fa-signal-stream"></i> <span v-html="AgendaEntry.stream"></span><br></span>
          </div>
          <div class="col-lg-3 col-6">
           <span class="Role copydetailxs">
                <span class="headline_light text-center">Share on</span><br>
                  <a class="a-link--external share buttonshare" @click="share('twitter','','en_us')" data-label="twitter"><i class="fab fa-2x fa-x-twitter pe-2"></i>
                    <span class="sr-only">X (Twitter)</span></a>
                  <a class="a-link--external share buttonshare" @click="share('linkedin','','en_us')" data-label="linkedin"><i class="fab fa-2x fa-linkedin-in pe-2"></i>
                  <span class="sr-only">LinkedIn</span></a>
                  <a class="a-link--external share buttonshare" @click="share('facebook','','en_us')" data-label="facebook"><i class="fab fa-2x fa-facebook-f"></i>
                  <span class="sr-only">Facebook</span></a>
                  <br><br>
            </span>
          </div>
          <div class="col-lg-3 col-6">
           <span class="Role copydetailxs">
                <span class="headline_light text-center">Bookmark</span><br>
                  <a class="a-link--external share buttonshare" title="Bookmark" @click="unsetFav(AgendaEntry.objvalueid)" v-if="Include(AgendaEntry.objvalueid)"><i class="fal fa-calendar-plus fa-2x"></i></a>
                  <a class="a-link--external share buttonshare" title="Bookmark" @click="setFav(AgendaEntry.objvalueid)" v-if="Exclude(AgendaEntry.objvalueid)"><i class="fal fa-calendar-minus fa-2x"></i></a>
                  <br><br>
            </span>
          </div>
          <div class="col-lg-3 col-6">
           <span class="Role copydetailxs">
                <span class="headline_light text-center">ICS DOWNLOAD </span><br>
                   <a class="a-link--external share buttonshare" title="ICS Download" @click="generateFileICS(AgendaEntry.objvalueid)"><i class="fal fa-calendar-check fa-2x"></i></a>
                  <br><br>
            </span>
          </div>

          <hr class="mt-5 mb-5" v-if="AgendaEntry.show_video && AgendaEntry.video_link">

          <div class="embed-responsive embed-responsive-16by9" v-if="AgendaEntry.show_video && AgendaEntry.video_link">
          <iframe title="" class="embed-responsive-item" v-if="AgendaEntry.show_video && AgendaEntry.video_link" :src="AgendaEntry.video_link" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>

          <hr class="mt-5 mb-5">

          <div v-if="AgendaEntry.show_area">

            <div v-if="Speakers.length > 0">
              <h3>Speakers</h3>
              <div class="row m-1">
                <div class="col-md-6 row" v-for="(ae, aeindex) in Speakers" v-if="ae.objvalueid">
                  <div class="col-4 mb-5 no-gutters">
                    <a @click="navigateMember(ae.objvalueid)" class="pointer">
                      <div class="circular_image">
                        <img :src="`/files/members/${ae.objvalueid}/foto.jpg`" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
                      </div>
                    </a>
                  </div>
                  <div class="col-8 mb-5 text-left">
                    <a @click="navigateMember(ae.objvalueid)" class="pointer">
                      <span class="Name-Nachname">{{ae.firstname}} {{ae.lastname}}</span><br>
                      <span class="Role copydetailxs">{{ae.position}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>


            <div v-if="Moderators.length > 0">
              <h3>Moderator</h3>
              <div class="row m-1">
                <div class="col-md-6 row" v-for="(ae, aeindex) in Moderators" v-if="ae.objvalueid">
                  <div class="col-4 mb-5 no-gutters">
                    <a @click="navigateMember(ae.objvalueid)" class="pointer">
                      <div class="circular_image">
                        <img :src="`/files/members/${ae.objvalueid}/foto.jpg`" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
                      </div>
                    </a>
                  </div>
                  <div class="col-8 mb-5 text-left">
                    <a @click="navigateMember(ae.objvalueid)" class="pointer">
                      <span class="Name-Nachname">{{ae.firstname}} {{ae.lastname}}</span><br>
                      <span class="Role copydetailxs">{{ae.position}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="Artists.length > 0">
              <h3>Artists</h3>
              <div class="row m-1">
                <div class="col-md-6 row" v-for="(ae, aeindex) in Artists" v-if="ae.objvalueid">
                  <div class="col-4 mb-5 no-gutters">
                    <a @click="navigateMember(ae.objvalueid)" class="pointer">
                      <div class="circular_image">
                        <img :src="`/files/members/${ae.objvalueid}/foto.jpg`" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
                      </div>
                    </a>
                  </div>
                  <div class="col-8 mb-5 text-left">
                    <a @click="navigateMember(ae.objvalueid)" class="pointer">
                      <span class="Name-Nachname">{{ae.firstname}} {{ae.lastname}}</span><br>
                      <span class="Role copydetailxs">{{ae.position}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!AgendaEntry.show_area && (Artists.length > 0 || Speakers.length > 0 || Moderators.length > 0)">
            <p>Please find more details about speakers later here.</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import _ from "lodash";
import {NavigationGuardNext, Route} from "vue-router";
import {AgendaPoint, MemberEntry} from "@/store/i/types";
import {DateTime} from "luxon";
import {TranslatedText} from "@/store/types";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";

@Component({
  components: {},
})
export default class AgendaDetail extends Vue {

  protected created() {
    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }

    this.$gtm.trackEvent({
      pageCategory: 'Program',
      PageName: 'ProgramPage',
      PageType: 'Webapp',
      Stage:stage,
    });

  if(window.scrollY > 0 ){
      localStorage.setItem("AgendaScrollPos", window.scrollY.toString())
      this.$router.go(0)
  }
    
    
    
  }
  
  

  get AgendaEntry(): AgendaPoint|undefined {
    return _.find(
        this.$store.state.i.agenda,
        (e: AgendaPoint) => `${e.objvalueid}` === `${this.$route.params.agendaid}`
    )
  }

  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }

  protected Include(objvalueid: any) {
    return _.has(this.$store.state.i.me.fav_agenda_items, objvalueid)
  }

  protected Exclude(objvalueid: any) {
    return !_.has(this.$store.state.i.me.fav_agenda_items, objvalueid)
  }

  protected errorstring: TranslatedText = {'de': '', 'en': ''}

  protected async setFav(objvalueid: any) {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/setDataFav", {objvalueid: objvalueid})
      if (dpr.sv) {
      } else {
        this.errorstring = {
          de: "Feld konnte nicht gesetzt werden.",
          en: "Could not set Field."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async generateFileICS(objvalueid: any) {
    window.location.href = '/projects/dw/gmf/sources/scripts/portal.php?&action=generateFileICS&objvalueid='+objvalueid;
  }

  protected async unsetFav(objvalueid: any) {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/setDataUnFav", {objvalueid: objvalueid})
      if (dpr.sv) {
      } else {
        this.errorstring = {
          de: "Feld konnte nicht gesetzt werden.",
          en: "Could not set Field."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }


  protected navigateMember(objvalueid: any) {
    this.$router.push({name: 'SpeakerDetail', params: { speakerid: `${objvalueid}` }})
  }

  protected formatedDate(startdate: string, enddate: string): string {
    return DateTime.fromISO(startdate, {locale: 'en-us'}).monthLong+" "+DateTime.fromISO(startdate).toFormat('d, h:mm a')+" - "+DateTime.fromISO(enddate).toFormat('h:mm a')
  }

  protected share(action: any, text: any, language: any){
        const link = window.location.href;
        const domain = window.location.hostname;

        let appendLanguage = '';
        if(language === 'en_us') {
          appendLanguage = ((link.indexOf('?') !== -1) ? '&' : '?') + 'hl=en';
        }

        const encodedUrl = encodeURIComponent(link + appendLanguage);

        let subject = $(this).closest('ul').data('subject');
        let fulltext = text + " " + encodedUrl;

        if (action === "facebook") {
          window.open("https://www.facebook.com/sharer.php?u=" + encodedUrl);
        } else if (action === "twitter") {
          window.open("https://twitter.com/intent/tweet?source=webclient" + "&url=" + encodedUrl);
        } else if (action === "linkedin") {
          window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + encodedUrl);
        }
  }

  get Moderators(): MemberEntry[] {
    return _.filter(
        this.$store.state.i.speakers,
        (ap: MemberEntry) => ap.dbstate > -1 && _.has(this.mod, ap.objvalueid) && ap.is_visible
    );
  }

  get mod(): any {
    if(this.AgendaEntry){
      return this.AgendaEntry.moderators
    }else{
      return 0
    }
  }


  get Speakers(): MemberEntry[] {
    return _.filter(
        this.$store.state.i.speakers,
        (ap: MemberEntry) => ap.dbstate > -1 && _.has(this.spe, ap.objvalueid) && ap.is_visible
    );
  }

  get spe(): any {
    if(this.AgendaEntry){
      return this.AgendaEntry.speakers
    }else{
      return 0
    }
  }


  get Artists(): MemberEntry[] {
    return _.filter(
        this.$store.state.i.speakers,
        (ap: MemberEntry) => ap.dbstate > -1 && _.has(this.art, ap.objvalueid) && ap.is_visible
    );
  }

  get art(): any {
    if(this.AgendaEntry){
      return this.AgendaEntry.artists
    }else{
      return 0
    }
  }



}


</script>

<style scoped>

</style>